import { EcSpaConfig } from './types'
import compact from 'lodash/compact'
import map from 'lodash/map'
import flatten from 'lodash/flatten'

/*
this config is for any "regular" spa. i.e. a spa loaded on a few pages or under a path namespace

`serverPaths` defines paths where the spa should be shown,
but the page containers some server-rendered content
and therefore any navigation to it must cause a full page refresh.

`clientPaths` defines paths where the spa is loaded AND there is no server-rendered content displayed,
i.e. the spa displays all of the main content on screen besides global navigation.

when navigating to a path matching clientPaths, the app will use the client side `singleSpaNavigate` and will not cause a full page refresh.

'ec-navigation-spa' and 'spa-dev-tools' are shown on all or almost all pages,
and so are configured in their  own files
*/

const spaConfigs: EcSpaConfig[] = [
  {
    name: 'ec-dashboard-spa',
    clientPaths: ['/:companyCode/dashboard']
  },
  {
    name: 'ec-human-resources-spa',
    clientPaths: ['/:client/employees/*'],
    serverPaths: [
      '/Employee/gross2Netv2.aspx',
      '/Employee/OpenEnrollment.aspx',
      '/Benefits/OpenEnrollment.aspx',
      '/Employee/PositionDetail.aspx',
      '/mvc/:client/:area/CareerProfile/*/:employee_id',
      '/mvc/:client/:area/Performance/*/:employee_id',
      '/mvc/:client/:area/StatusChanges/*/:employee_id',
      '/mvc/:client/:area/Benefits/*/:employee_id',
      '/mvc/:client/:area/Pay/*/:employee_id',
      '/mvc/:client/:area/TimeAndLabor/*/:employee_id',
      '/mvc/:client/:area/AssetsAndExpenses/*/:employee_id',
      '/mvc/:client/:area/DocumentsAndForms/*/:employee_id',
      '/mvc/:client/:area/PersonalProfile/*/:employee_id',
      '/mvc/:client/Team/CareerProfile/Details/:employee_id',
      '/mvc/:client/Team/CareerProfile/EmployeeJobs/:employee_id',
      '/mvc/:client/Team/CareerProfile/EmploymentSummary/:employee_id',
      '/mvc/:client/MySelf/CareerProfile/EmploymentSummary/:employee_id',
      '/:client/employees/:employee_id/paymentMethod/direct-deposit'
    ]
  },
  {
    // this path is kind of a scaffold path to unblock dev but eventually this spa will be loaded as widget, and since as a widget you don't own the whole page, it isn't a client path.
    // it's always safe to start out as a server path and "upgrade" it later.
    name: 'ec-add-employee-spa',
    serverPaths: ['/:companyCode/add-employee']
  },
  {
    name: 'ec-payroll-spa',
    clientPaths: [
      '/:client/payroll/:payrollUuid/paycycle/preview',
      '/:client/payroll/:payrollUuid/paycycle/employees',
      '/:client/payroll/:payrollUuid/paycycle/review',
      '/:client/payroll/payrolls/*wildcard',
      '/:client/payroll/forms/*wildcard',
      /*
        The following two routes are only needed to support old routes
        that esx-web generated so that we could supply the secureID.
        We no longer need esx-web to generate the secureID in this way, but
        keep these around for legacy support (e.g. for lingering bookmarks).
        Once enough time has passed, these can be deleted.
      */
      '/:client/payroll/details/*wildcard',
      '/:client/payroll/employees/*wildcard'
    ],
    serverPaths: [
      '/mvc/:client/admin/payrollops/*wildcard',
      '/mvc/:client/Company/Payroll/TimeImport/:id',
      '/mvc/:client/Company/Payroll/AlohaLaborReport/:id',
      '/Customer/ManagePayrollToDos/Index.aspx',
      '/Payroll/voidCheck.aspx',
      '/mvc/:client/Management/AssetsAndExpenses/Expense',
      '/mvc/:client/Company/Payroll/QuickCalcs',
      // only for serving form 8655 document courier -- targeting a move to tax center after tax center matures
      '/mvc/:client/Company/Reports/YearEnd',
      '/mvc/:client/Management/TimeAndLabor/Timesheets'
    ]
  },
  {
    name: 'ec-simplified-login-spa',
    serverPaths: ['/sign-in', '/sign-in/*', '/CompanyCode', '/CompanyCode/*']
  },
  {
    name: 'ec-settings-navigation-spa',
    serverPaths: [
      '/mvc/:client/Admin/ClientAdmin/POSProviderSetting',
      '/mvc/:client/Company/Setup/Dashboard',
      '/mvc/:client/Company/NewHire/RequiredDocuments',
      '/mvc/:client/Company/NewHire/Security',
      '/mvc/:client/Company/NewHire/Settings',
      '/mvc/:client/Company/NewHire/SurveysIndex',
      '/mvc/:client/Company/NewHire/Templates',
      '/mvc/:client/Company/NewHire/Workflows',
      '/mvc/:client/Company/Payroll/Settings',
      '/mvc/:client/Company/Payroll/TimeImport',
      '/mvc/:client/Company/Recruitment/BackgroundCheck',
      '/mvc/:client/Company/Recruitment/Security',
      '/mvc/:client/Company/Recruitment/Settings',
      '/mvc/:client/Company/Recruitment/SetupPages',
      '/mvc/:client/Company/Recruitment/Workflows',
      '/mvc/:client/Company/Setup/ACAAccruals',
      '/mvc/:client/Company/Setup/ACAMonthlyHours',
      '/mvc/:client/Company/Setup/ACASettings',
      '/mvc/:client/Company/Setup/ACATiers',
      '/mvc/:client/Company/Setup/APISettings',
      '/mvc/:client/Company/Setup/Arrears',
      '/mvc/:client/Company/Setup/AssetAccrualsLiabilities',
      '/mvc/:client/Company/Setup/AssetAccrualsStartingBalances',
      '/mvc/:client/Company/Setup/AssetAccrualTiers',
      '/mvc/:client/Company/Setup/AssetAccrualTypes',
      '/mvc/:client/Company/Setup/AssetAccruals',
      '/mvc/:client/Company/Setup/SetupMasterAssetManagement',
      '/mvc/:client/Company/Setup/BenefitInstructionalText',
      '/mvc/:client/Company/Setup/Benefits',
      '/mvc/:client/Company/Setup/Billing',
      '/mvc/:client/Company/Setup/CustomAttributes',
      '/mvc/:client/Company/Setup/DirectDeposit',
      '/mvc/:client/Company/Setup/DirectDepositBulkAction',
      '/mvc/:client/Company/Setup/DocumentTypes',
      '/mvc/:client/Company/Setup/EEO',
      '/mvc/:client/Company/Setup/TaxAccounts',
      '/mvc/:client/Company/Setup/EmployeeImport',
      '/mvc/:client/Company/Setup/FEIN',
      '/mvc/:client/Company/Setup/FieldTranslation',
      '/mvc/:client/Company/Setup/IpAddress',
      '/mvc/:client/Company/Setup/LegacyReports',
      '/mvc/:client/Company/Setup/Levels',
      '/mvc/:client/Company/Setup/Location',
      '/mvc/:client/Company/Setup/LocationAddress',
      '/mvc/:client/Company/Setup/LoginSettings',
      '/mvc/:client/Company/Setup/MissingData',
      '/mvc/:client/Company/Setup/ModuleConfiguration',
      '/mvc/:client/Company/Setup/NewHireImport',
      '/mvc/:client/Company/Setup/PaycardSettings',
      '/mvc/:client/Company/Setup/PayGroup',
      '/mvc/:client/Company/Setup/PaycheckDelivery',
      '/mvc/:client/Company/Setup/PayrollCalendars',
      '/mvc/:client/Company/Setup/PayrollImport',
      '/mvc/:client/Company/Setup/PerformanceCycles',
      '/mvc/:client/Company/Setup/Position',
      '/mvc/:client/Company/Setup/ProgressiveDiscipline',
      '/mvc/:client/Company/Setup/Reporting',
      '/mvc/:client/Company/Setup/SalesLaborSetup',
      '/mvc/:client/Company/Setup/SecondaryRates',
      '/mvc/:client/Company/Setup/SecurityAudit',
      '/mvc/:client/Company/Setup/SequenceBenefits',
      '/mvc/:client/Company/Setup/Termination',
      '/mvc/:client/Company/Setup/TimeAwayFromWork',
      '/mvc/:client/Company/Setup/Timekeeping',
      '/mvc/:client/Company/Setup/TimekeepingSettings',
      '/mvc/:client/Company/Setup/VendorReports',
      '/mvc/:client/Company/Setup/Vendors',
      '/mvc/:client/Company/Setup/WashingtonLI',
      '/mvc/:client/Company/Setup/WorkflowRecipients',
      '/mvc/:client/Company/Setup/Workflows',
      '/mvc/:client/Company/Setup/WorkersComp',
      '/mvc/:client/Company/SystemSettings/LevelsSetup',
      '/mvc/:client/Company/SystemSettings/SchedulingSettings',
      '/mvc/:client/Company/SystemSettings/ShiftDifferentials',
      '/mvc/:client/Company/SystemSettings/TaxSettings',
      '/mvc/:client/Company/SystemSettings/Toast',
      '/mvc/:client/Company/SystemSettings/ToastEmployees',
      '/mvc/:client/Company/SystemSettings/ToastJobs',
      '/mvc/:client/Company/SystemSettings/ToastLocations',
      '/mvc/:client/Company/SystemSettings/POSTips',
      '/:client/Company/Setup/CompanySummary'
    ]
  },
  {
    name: 'ec-team-spa',
    clientPaths: [
      '/:companyCode/team',
      '/:companyCode/team/employees',
      '/:companyCode/team/employees/*'
    ],
    serverPaths: [
      '/Employee/TrainingEvents.aspx',
      '/mvc/:companyCode/Team/Dashboard/Index',
      '/mvc/:companyCode/Management/NewHire/Index',
      '/mvc/:companyCode/Management/NewHire',
      '/mvc/:companyCode/Management/NewHire/OnboardingChecklist',
      '/mvc/:companyCode/Management/NewHire/Hire',
      '/mvc/:companyCode/Management/NewHire/EmployerDemographics/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/EmployeeLogin/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/EmploymentDetails/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/EmployeePosition/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/PayInformation/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/WorkPosts/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/EmployerBenefits/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/EmployerTimeAway/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/EmployerDocuments/:employeeHash',
      '/mvc/:companyCode/Management/NewHire/EmployerReview/:employeeHash',
      '/Employee/TrainingEventAdd.aspx',
      '/Customer/IncidenceReporting.aspx',
      '/Customer/IncidenceAdd.aspx',
      '/mvc/:companyCode/Company/Payroll/PayChange',
      '/mvc/:companyCode/Company/Payroll/PayChangeSetup'
    ]
  },
  {
    name: 'ec-settings-spa',
    serverPaths: [
      '/mvc/:client/Company/Import/CustomerPosition',
      '/mvc/:client/Company/Import/W4',
      '/mvc/:client/Company/Import/PriorsParallels',
      '/mvc/:client/Company/Setup/Dashboard',
      '/mvc/:client/Company/SystemSettings/ToastLocations',
      '/mvc/:client/Company/SystemSettings/ToastJobs',
      '/mvc/:client/Company/Setup/TaxAccounts',
      '/mvc/:client/Company/Setup/MissingData',
      '/mvc/:client/Company/Setup/PaycheckDelivery',
      '/mvc/:client/Company/SystemSettings/POSTips',
      '/:client/Company/Setup/CompanySummary',
      '/mvc/:client/Admin/SiteAdmin/Rts',
      '/mvc/:client/Company/Setup/Vendors',
      '/mvc/:client/Company/SystemSettings/ToastEmployees'
      /**
       * This is setup for a company setup navigation update
      '/mvc/:client/Company/Setup/*',
      '/mvc/:client/Company/SystemSettings/*',
      '/mvc/:client/Company/NewHire/*',
      '/mvc/:client/Company/Recruitment/*',
      '/mvc/:client/Admin/ClientAdmin/POSProviderSetting'
       */
    ]
  },
  {
    name: 'ec-implementation-spa',
    serverPaths: ['/mvc/:client/Company/Implementation/StartImplementation'],
    clientPaths: ['/:client/implementation/create-company']
  },
  {
    name: 'ec-staff-tools-spa',
    clientPaths: ['/staff-tools', '/staff-tools/*']
  },
  {
    name: 'ec-payroll-operations-spa',
    clientPaths: ['/payroll-ops', '/payroll-ops/*'],
    serverPaths: [
      '/mvc/:client/Company/StandardReports/TaxFilingExclusionDetails'
    ]
  },
  {
    name: 'ec-user-migration-spa',
    clientPaths: [
      '/user-migration',
      '/user-migration/*',
      '/user/debug',
      '/user/debug/*'
    ]
  },
  {
    name: 'employee-benefits-spa',
    clientPaths: ['/:companyCode/benefits', '/:companyCode/benefits/*']
  },
  {
    name: 'team-user-migration-spa',
    clientPaths: ['/user/migration', '/user/migration/*', '/login/:companyCode/:registrationId/register']
  },
  {
    name: 'ec-employee-card-settings-spa',
    serverPaths: ['/mvc/:client/Company/Setup/PaycardSettings']
  },
  {
    name: 'bulk-import-direct-deposit-spa',
    serverPaths: ['/mvc/:client/Company/Setup/DirectDepositBulkAction']
  }
]

const allClientPaths = compact(flatten(map(spaConfigs, 'clientPaths')))
const allServerPaths = compact(flatten(map(spaConfigs, 'serverPaths')))

const spaNames = compact(flatten(map(spaConfigs, 'name')))

export { allClientPaths, allServerPaths, spaNames }

export default spaConfigs
